import { SmhApi } from "./SmhApi";
import { PaginatedResult, PaginationRequest } from "./ProjectModels";
import { MilestoneProductHistory } from "./MilestoneProductModels";
import { useMilestoneProductHistoryStore } from "./MilestoneProductHistoryStore";

export class MilestoneProductHistoryService {
  public static async getMilestoneProductHistory(
    productId: string,
    pagination: PaginationRequest
  ) {
    return SmhApi.getMilestoneProductHistory(
      productId,
      pagination,
      (result: PaginatedResult<MilestoneProductHistory>) => {
        useMilestoneProductHistoryStore.setState({
          milestoneProductHistory: result.items,
        });
        useMilestoneProductHistoryStore.setState({
          paginationResult: result.pagination,
        });
      }
    );
  }
}
