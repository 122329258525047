import React from 'react';
import {
    Button,
    PageSection,
    Panel,
    PanelMain,
    PanelMainBody,
    Text,
    TextContent,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {MainToolbar} from "../../designer/MainToolbar";
import Iframe from "react-iframe";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";

export const SmhLogsPage = () => {
    const hostname = window.location.hostname
    const kibanaDashboardHostname = (window.location.hostname === 'localhost') ? "localhost:5601" : `${hostname}/kibana`

    function getTitle() {
        return <TextContent>
            <Text component="h2">Logs</Text>
        </TextContent>
    }

    function getTools() {
        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button variant="link" icon={<RefreshIcon/>} style={{ color: 'white'}}/>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={getTitle()} tools={getTools()}/>
            </PageSection>
            <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                <Panel isScrollable>
                    <PanelMain maxHeight={'calc(100vh - 100px)'}>
                        <PanelMainBody>
                            <Iframe url={`https://${kibanaDashboardHostname}/app/dashboards?auth_provider_hint=anonymous1#/view/4f16c9fd-b3d9-4403-83e7-8cd79ed61384?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-24h%2Fh%2Cto%3Anow))&show-query-input=true&show-time-filter=true`}
                                    position="relative"
                                    width="100%"
                                    id="kibanaIframe"
                                    height="100%"
                                    styles={{height: "2000px"}}/>
                        </PanelMainBody>
                    </PanelMain>
                </Panel>
            </PageSection>
        </PageSection>
    )
}