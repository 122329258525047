/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, {FormEvent} from 'react';
import {Button, DatePicker, Flex, FlexItem, InputGroup, TextInput, TimePicker} from '@patternfly/react-core';
import '../../designer/karavan.css';
import SyncAltIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import dayjs from "dayjs";
import {useMilestoneProductStepsStore} from "../../api/MilestoneProductStepsStore";
import {MilestoneProductStepFilter} from "../../api/MilestoneProductModels";
import {MilestoneProductStepsService} from "../../api/MilestoneProductStepsService";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {PaginationRequest} from "../../api/ProjectModels";
import HistoryIcon from "@patternfly/react-icons/dist/esm/icons/history-icon";

export class Time {
    time: string
    hour: number | undefined
    minute: number | undefined
    second: number | undefined
    isValid: boolean | undefined


    constructor(time: string, hour: number | undefined, minute: number | undefined, second: number | undefined, isValid: boolean | undefined) {
        this.time = time;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.isValid = isValid;
    }
}

interface Props {
  canAddMilestones :boolean
}

export const MilestoneProductDetailsToolbar = (props: Props) => {
    const { setOperation, paginationRequest, setPaginationRequest} = useMilestoneProductStepsStore();

    const [name, setName] = React.useState('');

    const [fromDate, setFromDate] = React.useState<Date>();
    const [fromTime, setFromTime] = React.useState<Time>();

    const [toDate, setToDate] = React.useState<Date>();
    const [toTime, setToTime] = React.useState<Time>();

    let {productId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const toValidator = (date: Date) => {
        if (!fromDate) {
            return '';
        }

        let from = dayjs(fromDate).utc(true);
        let to = dayjs(date).utc(true);

        return (from.isAfter(to) ? 'To date must be after from date' : '');
    };

    const onFromDateChange = (event: FormEvent<HTMLInputElement>, inputDate: string, newFromDate: Date | undefined) => {
        if (newFromDate) {
            setFromDate(newFromDate);
        }
        else {
            setFromDate(undefined);
            setFromTime(undefined);
        }
    };

    const onFromTimeChange = (
        event: FormEvent<HTMLInputElement>,
        time: string,
        hour: number | undefined,
        minute: number | undefined,
        seconds: number | undefined,
        isValid: boolean | undefined
    )=> {
        let fromTime = new Time(time, hour, minute, seconds, isValid);
        setFromTime(fromTime);
    };

    const onToDateChange = (event: FormEvent<HTMLInputElement>, inputDate: string, newToDate: Date | undefined) => {
        if (newToDate) {
            setToDate(newToDate);
        }
        else {
            setToDate(undefined);
            setToTime(undefined);
        }
    };

    const onToTimeChange = (
        event: FormEvent<HTMLInputElement>,
        time: string,
        hour: number | undefined,
        minute: number | undefined,
        seconds: number | undefined,
        isValid: boolean | undefined
    )=> {
        let toTime = new Time(time, hour, minute, seconds, isValid);
        setToTime(toTime);
    };

    const constructFilterDateTime = (date: Date | undefined, time: Time | undefined) => {
        // Date is provided
        if (date) {
            // Time is provided
            if (time && time.isValid) {
                return dayjs(date).utc(true)
                    .hour(time.hour || 0)
                    .minute(time.minute || 0)
                    .second(time.second || 0)
            }

            return dayjs(date).utc(true);
        }

        // Nothing was provided
        return undefined;
    }

    const searchMilestoneProductSteps = () => {
        let from = constructFilterDateTime(fromDate, fromTime);
        let to = constructFilterDateTime(toDate, toTime);

        const newPaginationRequest = new PaginationRequest(1, paginationRequest.size);
        setPaginationRequest(newPaginationRequest);

        const filter = MilestoneProductStepFilter.default();
        filter.from = from?.toDate();
        filter.to = to?.toDate();
        filter.name = name;

        if(productId){
            MilestoneProductStepsService.getMilestoneProductSteps(productId, newPaginationRequest,  filter);
        }

        useMilestoneProductStepsStore.getState().setActiveFilter(filter);
    }

    function handleAssignToMilestone() {
        setOperation("assign");
    }

    return (
        <Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <DatePicker
                            value={fromDate ? dayjs(fromDate).utc(true).format("YYYY-MM-DD") : undefined}
                            onChange={onFromDateChange}
                            aria-label="Start date"
                            placeholder="YYYY-MM-DD"
                        />
                        <TimePicker
                            aria-label="Start time"
                            style={{width: '150px'}}
                            time={fromTime?.time || '12:00 AM'}
                            onChange={onFromTimeChange}
                            isDisabled={!fromDate}
                            stepMinutes={15}
                        />
                    </InputGroup>
                </FlexItem>
                <FlexItem>
                </FlexItem>
                <FlexItem>
                    <InputGroup>
                        <DatePicker
                            onChange={onToDateChange}
                            rangeStart={fromDate ? dayjs(fromDate).utc(true).toDate() : undefined}
                            validators={[toValidator]}
                            aria-label="End date"
                            placeholder="YYYY-MM-DD"
                        />
                        <TimePicker
                            style={{width: '150px'}}
                            time={toTime?.time || '12:00 AM'}
                            onChange={onToTimeChange}
                            isDisabled={!toDate}
                            stepMinutes={15}
                        />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <InputGroup>
                        <TextInput style={{width: '200px'}}  value={name} type="text" onChange={event => setName(event.currentTarget.value)} aria-label="Product step name" placeholder="Product step name" />
                    </InputGroup>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <Button size={"sm"} variant={"secondary"} icon={<SyncAltIcon/>}
                            onClick={e =>
                                searchMilestoneProductSteps()}>Search</Button>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                    <Button size={"sm"} variant={"primary"} icon={<SyncAltIcon/>} isDisabled={props.canAddMilestones}
                            onClick={e =>
                                handleAssignToMilestone()
                    }> Assign to milestone
                    </Button>
                </FlexItem>
            </Flex>
            <Flex>
                <FlexItem>
                <Button
                    className="dev-action-button "
                    isDisabled={false}
                    size="sm"
                    variant="primary"
                    icon={<HistoryIcon />}
                    onClick={(e) => {
                    navigate("/product-milestones/history/" + productId, {
                        state: {
                        from: location.pathname,
                        },
                    });
                    }}
                >
                    History
                </Button>
                </FlexItem>
            </Flex>
        </Flex>
    );
}
