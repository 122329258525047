import React, { useEffect } from "react";
import {
  Bullseye,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  FlexItem,
  Label,
  PageSection,
  Pagination,
  PaginationVariant,
  Panel,
  PanelFooter,
  PanelMain,
  PanelMainBody,
  Text,
  TextContent,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import "../../designer/karavan.css";
import { PaginationRequest } from "../../api/ProjectModels";
import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import { MainToolbar } from "../../designer/MainToolbar";
import SearchIcon from "@patternfly/react-icons/dist/esm/icons/search-icon";
import {
  MilestoneProductHistory,
} from "../../api/MilestoneProductModels";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlagIcon from "@patternfly/react-icons/dist/esm/icons/flag-icon";
import AngleLeftIcon from "@patternfly/react-icons/dist/esm/icons/angle-left-icon";
import { MilestoneProductHistoryService } from "../../api/MilestoneProductHistoryService";
import { useMilestoneProductHistoryStore } from "../../api/MilestoneProductHistoryStore";

export const MilestoneProductHistoryPage = () => {
  const {
    milestoneProductHistory,
    paginationRequest,
    setPaginationRequest,
    paginationResult,
  } = useMilestoneProductHistoryStore();

   const defaultPaginationRequest = PaginationRequest.default();
  const navigate = useNavigate();
  const location = useLocation();

  let { productId } = useParams();

  let lineNumber = 1;

  useEffect(() => {
    setPaginationRequest(defaultPaginationRequest);
  }, []);

  useEffect(() => {
    if (productId) {
      MilestoneProductHistoryService.getMilestoneProductHistory(
        productId,
         defaultPaginationRequest,
      );
    }
  }, []);

  const getProductSteps = (
     pagination: PaginationRequest,
  ) => {
    if (productId) {
      return MilestoneProductHistoryService.getMilestoneProductHistory(
        productId,
         pagination,
      );
    }
  };

  const onSetPage = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(
      newPage,
      paginationRequest.size
    );

    setPaginationRequest(newPaginationRequest);
    return getProductSteps(newPaginationRequest);
  };

  const onPerPageSelect = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

    setPaginationRequest(newPaginationRequest);
    return getProductSteps(newPaginationRequest);
  };

  

  function getTitle() {
    return (
      <TextContent>
        <Flex
          direction={{ default: "row" }}
        >
          <Text component="h2" style={{ marginRight: "4px" }}>      
            Product ID:
          </Text>
          <Text component="p" style = {{fontSize: "18px"}}>
            {milestoneProductHistory[0]?.milestoneProduct.productId}
          </Text>
        </Flex>
      </TextContent>
    );
  }

  function getTools() {
    return (
      <Toolbar id="toolbar-group-types">
        <ToolbarContent>
          <ToolbarItem>
            <Button
              variant="link"
              icon={<RefreshIcon />}
              onClick={(e) => getProductSteps(paginationRequest)}
            />
          </ToolbarItem>
        </ToolbarContent>
      </Toolbar>
    );
  }

  function getIcon() {
    return <FlagIcon />;
  }


  function getFirstMilestoneStep(milestoneStepId: number) {
    const minOrderStep = Math.min(
      ...milestoneProductHistory
        .filter((step) => step.milestoneStep?.id === milestoneStepId)
        .map((o) => o.sortOrder)
    );
    return milestoneProductHistory.filter(
      (obj) => obj.sortOrder === minOrderStep
    )[0];
  }

  function isFirstStepInMilestone(productStep: MilestoneProductHistory) {
    if (productStep.milestoneStep) {
      const firstStep = getFirstMilestoneStep(productStep.milestoneStep?.id);
      return firstStep ? firstStep.id === productStep.id : false;
    } else {
      return false;
    }
  }

  const isFirstPage = paginationRequest.page ===1;
  const latestUpdatedDate = isFirstPage ? milestoneProductHistory[0]?.updatedAt : null;


  const highlightedMilestoneRows = milestoneProductHistory.map(row =>({
  ...row,
  isLatest:  row.updatedAt === latestUpdatedDate
  }))


  function getMilestoneLabel(productStep: MilestoneProductHistory) {
    if (isFirstStepInMilestone(productStep)) {
      return (
        <Label icon={getIcon()} color={"blue"}>
          {productStep?.milestoneStep?.name}
        </Label>
      );
    } else {
      return <Label color={"grey"}>{productStep?.milestoneStep?.name}</Label>;
    }
  }


  const handleGoBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate("/product-milestones");
    }
  };
 

  return (
    <PageSection
      className="kamelet-section projects-page"
      padding={{ default: "noPadding" }}
    >
      <MainToolbar title={getTitle()} tools={getTools()} />
      <PageSection>
        <Flex
          className="toolbar"
          direction={{ default: "row" }}
          justifyContent={{ default: "justifyContentFlexStart" }}
        >
          <FlexItem>
            <Button
              className="dev-action-button "
              size="sm"
              variant="secondary"
              icon={<AngleLeftIcon />}
              onClick={(e) => {
                handleGoBack();
              }}
            >
              Go back
            </Button>
          </FlexItem>
        </Flex>
      </PageSection>
      <PageSection
        className="project-tab-panel"
        padding={{ default: "padding" }}
      >
        <Panel isScrollable>
          <PanelMain maxHeight={"calc(100vh - 300px)"}>
            <PanelMainBody>
              <Table
                aria-label="Products"
                variant={"compact"}
                className={"table"}
              
              >
                <Thead>
                  <Tr>
                    <Th key="name">Name</Th>
                    <Th key="order">Order</Th>
                    <Th key="process-name">Process Name</Th>
                    <Th key="description">Description</Th>
                    <Th key="milestone">Milestone</Th>
                    <Th key="createdAt">Created At</Th>
                    <Th key="updatedAt">Updated At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {highlightedMilestoneRows.map((productStep) => {
                    lineNumber = lineNumber + 1;
                 
                    let backgroundColor =  productStep.isLatest ? "#f3faf2": "white"

                    return (
                      <Tr
                        key={lineNumber}
                        style={{ backgroundColor: backgroundColor }}
                      >
                        <Td>{productStep.name}</Td>
                        <Td>{productStep.sortOrder}</Td>
                        <Td>{productStep.processName}</Td>
                        <Td>{productStep.description}</Td>
                        <Td>
                          {productStep?.milestoneStep &&
                            getMilestoneLabel(productStep)}
                        </Td>
                        <Td>{productStep.createdAt.toString()}</Td>
                        <Td>{productStep.updatedAt.toString()}</Td>
                      </Tr>
                    );
                  })}
                  {milestoneProductHistory.length === 0 && (
                    <Tr>
                      <Td colSpan={6}>
                        <Bullseye>
                          <EmptyState variant={EmptyStateVariant.sm}>
                            <EmptyStateIcon icon={SearchIcon} />
                            <Title headingLevel="h2" size="lg">
                              No results found
                            </Title>
                          </EmptyState>
                        </Bullseye>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </PanelMainBody>
          </PanelMain>
          <PanelFooter>
            <Pagination
              itemCount={paginationResult.totalItems}
              widgetId="bottom-mileston-products"
              perPage={paginationRequest.size}
              page={paginationRequest.page}
              variant={PaginationVariant.bottom}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            />
          </PanelFooter>
        </Panel>
       
      </PageSection>
    </PageSection>
  );
};
