import { createWithEqualityFn } from "zustand/traditional";

import { PaginationRequest, PaginationResult } from "./ProjectModels";
import { shallow } from "zustand/shallow";
import { MilestoneProductHistory } from "./MilestoneProductModels";

interface MilestoneProductHistoryState {
  milestoneProductHistory: MilestoneProductHistory[];

  paginationRequest: PaginationRequest;
  paginationResult: PaginationResult;
  setMilestoneProductHistory: (
    milestoneProductHistory: MilestoneProductHistory[]
  ) => void;

  setPaginationRequest: (pagination: PaginationRequest) => void;
  setPaginationResult: (pagination: PaginationResult) => void;
}

export const useMilestoneProductHistoryStore =
  createWithEqualityFn<MilestoneProductHistoryState>(
    (set) => ({
      milestoneProductHistory: [],

      paginationRequest: PaginationRequest.default(),
      paginationResult: PaginationResult.empty(),
      setMilestoneProductHistory: (
        milestoneProductHistory: MilestoneProductHistory[]
      ) => {
        set((state: MilestoneProductHistoryState) => ({
          milestoneProductHistory: milestoneProductHistory,
        }));
      },

      setPaginationRequest: (pagination: PaginationRequest) => {
        set((state: MilestoneProductHistoryState) => ({
          paginationRequest: pagination,
        }));
      },
      setPaginationResult: (pagination: PaginationResult) => {
        set((state: MilestoneProductHistoryState) => ({
          paginationResult: pagination,
        }));
      },
    }),
    shallow
  );
