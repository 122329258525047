import React from 'react';
import {PageSection, Panel, PanelMain, PanelMainBody,} from '@patternfly/react-core';
import '../../designer/karavan.css';
import {useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import Iframe from "react-iframe";

export const ProjectLogsTab = () => {
    const [project] = useProjectStore((state) => [state.project], shallow )
    const hostname = window.location.hostname
    const kibanaDashboardHostname = (hostname === 'localhost') ? "localhost:5601" : `${hostname}/kibana`

    return (
        <PageSection>
            <Panel>
                <PanelMain>
                    <PanelMainBody>
                        <Iframe url={`https://${kibanaDashboardHostname}/app/dashboards?auth_provider_hint=anonymous1#/view/4f16c9fd-b3d9-4403-83e7-8cd79ed61384?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-24h%2Fh%2Cto%3Anow)%2Cfilters%3A!(('$state'%3A(store:appState)%2Cmeta%3A(alias%3A'Project: ${project.projectId}'%2Cdisabled%3A!f%2Cindex%3A'df5b9dd5-134c-4dc3-9ae5-7f23212af632'%2Ckey%3Aservice.id%2Cnegate%3A!f%2Cparams%3A(query%3A${project.projectId})%2Ctype%3Aphrase),query%3A(match_phrase%3A(service.id%3A${project.projectId})))))&show-query-input=true&show-time-filter=true`}
                                position="relative"
                                width="100%"
                                id="kibanaIframe"
                                height="100%"
                                styles={{height: "2000px"}}/>
                    </PanelMainBody>
                </PanelMain>
            </Panel>
        </PageSection>
    )
}
