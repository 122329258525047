import React, {useEffect} from 'react';
import {
    Bullseye,
    Button,
    EmptyState,
    EmptyStateIcon,
    EmptyStateVariant,
    Flex,
    FlexItem,
    PageSection, Pagination, PaginationVariant,
    Panel, PanelFooter,
    PanelHeader,
    PanelMain,
    PanelMainBody,
    Text,
    TextContent, Title,
    Toolbar,
    ToolbarContent,
    ToolbarItem,
    Tooltip,
} from '@patternfly/react-core';
import '../../designer/karavan.css';

import {PaginationRequest} from "../../api/ProjectModels";
import {Table, Tbody, Td, Th, Thead, Tr} from "@patternfly/react-table";

import {shallow} from "zustand/shallow";

import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";
import {MainToolbar} from "../../designer/MainToolbar";
import DeleteIcon from "@patternfly/react-icons/dist/js/icons/times-icon";
import CopyIcon from "@patternfly/react-icons/dist/js/icons/copy-icon";
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import {DeleteProductModal} from "./DeleteProductModal";
import {ProductImportModal} from "./ProductImportModal";
import {ProductCloneModal} from "./ProductCloneModal";
import {MilestoneProductsService} from "../../api/MilestoneProductsService";
import {useMilestoneProductsStore} from "../../api/MilestoneProductStore";
import {MilestoneProductFilter} from "../../api/MilestoneProductModels";
import {MilestoneProductsToolbar} from "./MilestoneProductsToolbar";
import PlusIcon from "@patternfly/react-icons/dist/esm/icons/plus-icon";
import {useNavigate,useLocation} from "react-router-dom";
import HistoryIcon from "@patternfly/react-icons/dist/esm/icons/history-icon";

export const MilestoneProductsPage = () => {

    const {
        milestoneProducts,
        paginationRequest,
        setPaginationRequest,
        paginationResult,
        activeFilter
    } = useMilestoneProductsStore();
    const [setMilestoneProduct] = useMilestoneProductsStore((s) => [s.setMilestoneProduct], shallow);
    const defaultFilter =  MilestoneProductFilter.default();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        useMilestoneProductsStore.getState().setActiveFilter(defaultFilter)
    }, []);

    useEffect(() => {
        MilestoneProductsService.getMilestoneProducts(PaginationRequest.default(), defaultFilter);
    }, []);

    let lineNumber = 1;

    const getProducts = (pagination: PaginationRequest, filter: MilestoneProductFilter) => {
        return MilestoneProductsService.getMilestoneProducts(pagination, filter);
    }

    const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
        const newPaginationRequest = new PaginationRequest(newPage, paginationRequest.size);

        setPaginationRequest(newPaginationRequest);
        return getProducts(newPaginationRequest, activeFilter);
    };

    const onPerPageSelect = (
        _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
        newPerPage: number,
        newPage: number
    ) => {
        const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

        setPaginationRequest(newPaginationRequest);
        return getProducts(newPaginationRequest, activeFilter);
    };

    function getTitle() {
        return <TextContent>
            <Text component="h2">Products</Text>
        </TextContent>
    }

    function getTools() {
        return <Toolbar id="toolbar-group-types">
            <ToolbarContent>
                <ToolbarItem>
                    <Button variant="link" icon={<RefreshIcon/>} onClick={e => getProducts(paginationRequest, activeFilter)}/>
                </ToolbarItem>
                <ToolbarItem>
                    <Button icon={<PlusIcon/>} onClick={e => {
                        setMilestoneProduct("import");
                    }}>Add product</Button>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    }

    return (
        <PageSection className="kamelet-section projects-page" padding={{default: 'noPadding'}}>
            <PageSection className="tools-section" padding={{default: 'noPadding'}}>
                <MainToolbar title={getTitle()} tools={getTools()}/>
            </PageSection>
            <PageSection className="project-tab-panel" padding={{default: "padding"}}>
                <Panel isScrollable>
                    <PanelHeader style={{paddingBottom: '10px'}}>
                        <MilestoneProductsToolbar/>
                    </PanelHeader>
                    <PanelMain maxHeight={'calc(100vh - 300px)'}>
                        <PanelMainBody>
                            <Table aria-label="Products" variant={"compact"} className={"table"}>
                                <Thead>
                                    <Tr>
                                        <Th key='productId'>Product Id</Th>
                                        <Th key='name'>Name</Th>
                                        <Th key='description'>Description</Th>
                                        <Th key='createdAt'>Created At</Th>
                                        <Th key='actions'>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {milestoneProducts.map(product => {
                                        lineNumber = lineNumber + 1;
                                        let backgroundColor = lineNumber % 2 ? 'white' : '#f0f0f0';

                                        return <Tr key={lineNumber} style={{backgroundColor: backgroundColor}}>
                                            <Td>
                                                <Button style={{padding: '6px'}} variant={"link"} onClick={e => {
                                                    navigate("/product-milestones/" + product.id );
                                                }}>
                                                    {product.productId}
                                                </Button>
                                            </Td>
                                            <Td>{product.name}</Td>
                                            <Td>{product.description}</Td>
                                            <Td>{product.createdAt.toString()}</Td>
                                            <Td className="project-action-buttons">
                                                <Flex direction={{default: "row"}}
                                                      spaceItems={{default: 'spaceItemsNone'}}>
                                                    <FlexItem>
                                                        <Tooltip content={"Clone product"} position={"bottom"}>
                                                            <Button className="dev-action-button" variant={"plain"}
                                                                    icon={<CopyIcon/>} onClick={e => {
                                                                setMilestoneProduct("clone", product);
                                                            }}></Button>
                                                        </Tooltip>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <Tooltip content={"Delete product"} position={"bottom"}>
                                                            <Button className="dev-action-button" variant={"plain"}
                                                                    icon={<DeleteIcon/>} onClick={e => {
                                                                setMilestoneProduct("delete", product);
                                                            }}></Button>
                                                        </Tooltip>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <Tooltip content={"History"} position={"bottom"}>
                                                            <Button
                                                            className="dev-action-button"
                                                            variant={"link"}
                                                            icon={<HistoryIcon />}
                                                            onClick={(e) => {
                                                                navigate(
                                                                "/product-milestones/history/" +
                                                                    product.id,
                                                                {
                                                                    state: {
                                                                    from: location.pathname,
                                                                    },
                                                                }
                                                                );
                                                            }}
                                                            ></Button>
                                                        </Tooltip>
                                                     </FlexItem>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    })}
                                    {milestoneProducts.length === 0 &&
                                        <Tr>
                                            <Td colSpan={6}>
                                                <Bullseye>
                                                    <EmptyState variant={EmptyStateVariant.sm}>
                                                        <EmptyStateIcon icon={SearchIcon}/>
                                                        <Title headingLevel="h2" size="lg">
                                                            No results found
                                                        </Title>
                                                    </EmptyState>
                                                </Bullseye>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </Table>
                        </PanelMainBody>
                    </PanelMain>
                    <PanelFooter>
                        <Pagination
                            itemCount={paginationResult.totalItems}
                            widgetId="bottom-mileston-products"
                            perPage={paginationRequest.size}
                            page={paginationRequest.page}
                            variant={PaginationVariant.bottom}
                            onSetPage={onSetPage}
                            onPerPageSelect={onPerPageSelect}
                        />
                    </PanelFooter>
                </Panel>
                <DeleteProductModal/>
                <ProductImportModal/>
                <ProductCloneModal/>
            </PageSection>
        </PageSection>
    )
}